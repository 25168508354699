import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const toggleMenu = (e) => {
        e.stopPropagation();
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const handleClickOutside = (event) => {
        if (!event.target.closest('.navbar-container')) {
            closeMenu();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleScroll = () => {
        if (window.scrollY > 50) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const logoUrl = process.env.REACT_APP_LOGO_URL;

    return (
        <nav className={`navbar ${isScrolled ? "scrolled" : ""}`} onClick={closeMenu}>
            <div className="navbar-container">
                <Link to="/" className="navbar-logo-link">
                    <img src={logoUrl} alt="Logo" className="navbar-logo" />
                </Link>
                <div className="hamburger-menu" onClick={toggleMenu}>
                    &#9776; {/* Hamburger icon */}
                </div>
                <ul className={`navbar-list ${isMenuOpen ? "open" : ""}`} onClick={(e) => e.stopPropagation()}>
                    <li className="navbar-item"><Link to="/massage" onClick={closeMenu}>Massage</Link></li>
                    <li className="navbar-item"><Link to="/ansiktsbehandlingar" onClick={closeMenu}>Ansiktsbehandlingar</Link></li>
                    <li className="navbar-item"><Link to="/fransar-och-bryn" onClick={closeMenu}>Fransar & bryn</Link></li>
                    <li className="navbar-item"><Link to="/vaxning" onClick={closeMenu}>Vaxning</Link></li>
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;