import React, { useEffect } from "react";
import { Route, Routes, useLocation, Link } from "react-router-dom";

import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Massage from "./components/Massage";
import Ansiktsbehandlingar from "./components/Ansiktsbehandlingar";
import FransarOchBryn from "./components/FransarOchBryn";
import Vaxning from "./components/Vaxning";
import Footer from "./components/Footer"; // Import the Footer component

import "./App.css";

const AppRoutes = () => {
    const location = useLocation();
    const darkBackground = process.env.REACT_APP_DARK_BACKGROUND_URL;
    const lightBackground = process.env.REACT_APP_LIGHT_BACKGROUND_URL;
    const logoUrl = process.env.REACT_APP_LOGO_URL;
    const massageUrl = process.env.REACT_APP_MASSAGE_URL;
    const ansiktsbehandlingarUrl = process.env.REACT_APP_ANSIKTE_URL;
    const fransarOchBrynUrl = process.env.REACT_APP_FRANSAR_BRYN_URL;
    const vaxningUrl = process.env.REACT_APP_VAXNING_URL;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            <Navbar />
            <div className="background-container">
                <img src={lightBackground} alt="Background" className="background-image" />
                <div className="overlay-content">
                    <Link to="/kontakta-oss" className="overlay-button">Kontakta oss</Link>
                    <div className="vertical-line"></div>
                    <Link to="/om-oss" className="overlay-button">Om oss</Link>
                    <div className="vertical-line"></div>
                    <a href="https://business.bokadirekt.se/bransch/massage" target="_blank" rel="noopener noreferrer" className="overlay-button">Boka tid</a>
                </div>
            </div>
            <div className="content-container">
                <Routes>
                    <Route path="/" element={<Home massageUrl={massageUrl} vaxningUrl={vaxningUrl} ansiktsUrl={ansiktsbehandlingarUrl} fransarOchBrynUrl={fransarOchBrynUrl} />} />
                    <Route path="/massage" element={<Massage />} />
                    <Route path="/ansiktsbehandlingar" element={<Ansiktsbehandlingar />} />
                    <Route path="/fransar-och-bryn" element={<FransarOchBryn />} />
                    <Route path="/vaxning" element={<Vaxning />} />
                </Routes>
            </div>
            <Footer />
        </>
    );
}

export default AppRoutes;